define("ember-svg-jar/inlined/pending-invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<mask id=\"a\" style=\"mask-type:luminance\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"24\" height=\"24\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 0h24v24H0V0z\" fill=\"#fff\"/></mask><g mask=\"url(#a)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#919BA1\"><path d=\"M19.473 22.001H4.527a4.436 4.436 0 01-3.203-1.361A4.634 4.634 0 010 17.37V8.63c0-1.23.466-2.387 1.313-3.257a4.437 4.437 0 013.211-1.374h14.949l.147.003H11c-.001.004-.258.847-.376 1.814H4.527a2.676 2.676 0 00-1.91.818 2.859 2.859 0 00-.8 1.996v8.742c0 .76.286 1.47.806 1.998a2.646 2.646 0 001.904.812h14.946a2.66 2.66 0 001.91-.815 2.86 2.86 0 00.803-1.995v-5.044c.588-.476 1.199-1.192 1.815-2.129v7.173a4.67 4.67 0 01-1.314 3.26A4.5 4.5 0 0119.474 22zM4.94 18.953a.906.906 0 01-.657-1.534l3.77-3.958-3.761-3.866a.907.907 0 111.3-1.265l6.313 6.493c.008-.007 1.216-1.176 1.624-1.573.325-.318.667-.48 1.014-.48.714 0 1.212.684 1.217.691l3.767 3.958a.91.91 0 01-.658 1.533c-.25 0-.483-.1-.655-.28l-3.722-3.906-1.842 1.895a.868.868 0 01-.631.26c-.037 0-.075-.002-.114-.006a.868.868 0 01-.743-.254l-1.844-1.895-3.72 3.906a.903.903 0 01-.658.28z\"/><path d=\"M23.868 1.978l-.002 4.001a.895.895 0 01-.9.9l-.091-.007c-.037.006-.071.006-.11.007a.791.791 0 01-.217-.026L18.55 5.85a.9.9 0 01.435-1.745l1.61.4a3.203 3.203 0 00-4.985-.58 3.202 3.202 0 00-.001 4.525 3.202 3.202 0 005.142-.87c.658.218 1.248.381 1.756.483a5.008 5.008 0 01-8.172 1.66 5.008 5.008 0 010-7.071 5.006 5.006 0 017.734.82V1.977c-.002-.498.4-.9.9-.9.247 0 .472.1.636.265a.892.892 0 01.263.634z\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});