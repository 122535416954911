define("ember-svg-jar/inlined/credit-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.437 17.5A2.443 2.443 0 010 15.056V4.944A2.443 2.443 0 012.437 2.5h15.127A2.443 2.443 0 0120 4.944v10.113a2.443 2.443 0 01-2.436 2.443H2.437zm-.924-2.444c0 .511.415.928.924.928h15.127c.509 0 .924-.417.924-.928V9.58H1.512v5.477zM18.487 6.63V4.944a.927.927 0 00-.923-.928H2.437a.927.927 0 00-.924.928V6.63h16.974zM3.27 15.056a.833.833 0 01-.833-.833v-1.705c0-.46.373-.833.833-.833h1.695c.46 0 .833.374.833.833v1.704c0 .461-.372.834-.833.834H3.27z\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "#919BA1",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});