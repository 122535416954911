define("ember-svg-jar/inlined/smile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<mask id=\"a\" style=\"mask-type:luminance\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"24\" height=\"24\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 0h24v24H0V0z\" fill=\"#fff\"/></mask><g mask=\"url(#a)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.515 20.486A11.921 11.921 0 010 12c0-3.206 1.248-6.219 3.515-8.485A11.917 11.917 0 0112 0c3.206 0 6.219 1.248 8.486 3.515A11.92 11.92 0 0124 12c0 3.206-1.248 6.219-3.514 8.486A11.924 11.924 0 0112 24a11.92 11.92 0 01-8.485-3.514zM1.815 12c0 5.616 4.569 10.185 10.185 10.185 5.616 0 10.185-4.569 10.185-10.185 0-5.616-4.569-10.185-10.185-10.185C6.384 1.815 1.815 6.384 1.815 12zm10.172 6.316c-2.928 0-5.648-1.494-7.156-3.935-.309-.5-.14-1.148.372-1.444a1.087 1.087 0 011.467.362c1.12 1.814 3.142 2.92 5.334 2.92 2.19-.006 4.209-1.127 5.317-2.946a1.076 1.076 0 011.458-.392c.253.14.437.373.51.65.074.275.029.57-.122.809-1.494 2.452-4.212 3.96-7.155 3.976h-.025zm3.408-7.072c-2.351-.005-2.346-3.534.008-3.53 2.351.005 2.346 3.53-.003 3.53h-.005zm-6.806 0c-2.353-.005-2.346-3.535.008-3.53 2.35.005 2.345 3.53-.003 3.53h-.005z\" fill=\"#919BA1\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});