define("ember-svg-jar/inlined/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 4v14a2 2 0 002 2h14a2 2 0 002-2V4a2 2 0 00-2-2h-2V0h-2v2H6V0H4v2H2a2 2 0 00-2 2zm16 14H2V6h14v12z\" fill=\"#919BA1\"/>",
    "attrs": {
      "width": "18",
      "height": "20",
      "viewBox": "0 0 18 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});