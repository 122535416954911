define("ember-svg-jar/inlined/user-checked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<mask id=\"a\" style=\"mask-type:luminance\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"24\" height=\"24\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 0h24v24H0V0z\" fill=\"#fff\"/></mask><g mask=\"url(#a)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#919BA1\"><path d=\"M23.492 15.759c.327.327.354.84.082 1.2l-.082.093-6.095 6.095a.914.914 0 01-1.293 0l-3.047-3.048a.914.914 0 011.2-1.375l.093.082 2.4 2.401L22.2 15.76a.914.914 0 011.293 0z\"/><path d=\"M1.419 24l-.115-.007a.915.915 0 01-.804-.907c0-4.714 2.917-8.742 7.021-10.315a7.004 7.004 0 01-3.05-5.794C4.472 3.13 7.558 0 11.35 0c3.791 0 6.876 3.13 6.876 6.977 0 3.847-3.085 6.978-6.876 6.978-.23 0-.457-.012-.68-.034-4.306.328-7.776 3.744-8.27 8.092l-.016.158h9.182l.114.008c.454.057.804.44.804.907a.917.917 0 01-.918.914H1.418zm10.233-11.883c2.638-.16 4.736-2.405 4.736-5.14 0-2.838-2.26-5.149-5.04-5.149-2.778 0-5.039 2.31-5.039 5.15 0 2.616 1.922 4.784 4.399 5.107.212-.013.425-.02.64-.02a.92.92 0 01.304.052z\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});