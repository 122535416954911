define("ember-svg-jar/inlined/badge-premium", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20.924 17.353c0 1.963-1.62 3.566-3.588 3.566h-1.79l-1.003 1.007c-1.377 1.428-3.662 1.43-5.073.015l-1.019-1.023H6.664a3.57 3.57 0 01-3.59-3.565v-1.799l-1.018-1.021a3.567 3.567 0 01-.003-5.064l1.021-1.024V6.647c0-1.963 1.62-3.566 3.588-3.566h1.79l1.003-1.007c1.377-1.428 3.663-1.43 5.073-.015l1.02 1.023h1.786a3.57 3.57 0 013.59 3.565v1.799l1.021 1.024c1.407 1.41 1.407 3.696 0 5.06l-1.02 1.026-.001 1.797z\" fill=\"url(#paint0_linear_107_38925)\" stroke=\"#F1F2F3\" stroke-width=\"1.8\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.617 8.4a.3.3 0 00-.239.118l-1.851 2.42a.3.3 0 00.028.397l5.234 5.1a.3.3 0 00.419 0l5.232-5.099a.3.3 0 00.027-.4l-1.892-2.42a.3.3 0 00-.237-.116H8.617zm.9.981a.1.1 0 01.088-.147h1.27a.1.1 0 01.077.165l-.778.908a.1.1 0 01-.164-.018l-.493-.908zm3.518.017a.1.1 0 01.076-.164h1.335a.1.1 0 01.081.16l-.623.842a.1.1 0 01-.156.005l-.713-.843zm-1.116.176a.1.1 0 01.153 0l.852 1.008a.1.1 0 01-.076.165h-1.716a.1.1 0 01-.076-.165l.864-1.008zm-3.366.116a.1.1 0 01.167.013l.486.896a.1.1 0 01-.088.148H7.946a.1.1 0 01-.08-.16l.687-.897zm6.757.074a.1.1 0 01.16-.002l.643.823a.1.1 0 01-.078.162h-1.252a.1.1 0 01-.08-.16l.607-.823zm-7.114 1.988a.1.1 0 01.07-.171H9.64a.1.1 0 01.092.061l.927 2.242c.041.1-.085.186-.163.11l-2.3-2.242zm2.494-.033a.1.1 0 01.093-.138h2.216a.1.1 0 01.095.133l-1.035 2.862a.1.1 0 01-.186.005l-1.183-2.862zm3.34-.072a.1.1 0 01.093-.066h1.607a.1.1 0 01.07.171L13.32 14.17c-.076.075-.2-.005-.164-.105l.874-2.418z\" fill=\"#fff\"/><defs><linearGradient id=\"paint0_linear_107_38925\" x1=\"5\" y1=\"25\" x2=\"20\" y2=\"5\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#CBA8EE\"/><stop offset=\"1\" stop-color=\"#5E37CD\"/></linearGradient></defs>",
    "attrs": {
      "width": "24",
      "height": "25",
      "viewBox": "0 0 24 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});