define("ember-svg-jar/inlined/user-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 0h24v24H0V0z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.527 21a4.445 4.445 0 01-3.203-1.362A4.655 4.655 0 010 16.362V7.638c0-1.23.467-2.39 1.312-3.264A4.482 4.482 0 014.523 3H19.474c2.493.008 4.523 2.089 4.526 4.638v8.724a4.676 4.676 0 01-1.312 3.265A4.497 4.497 0 0119.474 21H4.527zM2.616 5.64a2.859 2.859 0 00-.8 1.998v8.724c0 .758.286 1.469.806 2.002a2.65 2.65 0 001.905.815h14.947a2.65 2.65 0 001.91-.82c.517-.534.8-1.243.8-1.997V7.638c0-.748-.286-1.457-.807-1.996a2.652 2.652 0 00-1.903-.822H4.527c-.719.001-1.398.292-1.911.82zM12 15.943a.91.91 0 010-1.82h5.546a.91.91 0 010 1.82H12zm-5.298-2.426c-2.351-.004-2.347-3.544.008-3.54 2.351.004 2.346 3.54-.004 3.54h-.004zM12 12.91a.91.91 0 010-1.82h5.546a.91.91 0 010 1.82H12zm0-3.033a.91.91 0 010-1.82h5.546a.91.91 0 010 1.82H12z\" fill=\"#919BA1\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});