define("ember-svg-jar/inlined/contacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<mask id=\"a\" height=\"24\" maskUnits=\"userSpaceOnUse\" width=\"24\" x=\"0\" y=\"0\"><path clip-rule=\"evenodd\" d=\"M0 0h24v24H0z\" fill=\"#fff\" fill-rule=\"evenodd\"/></mask><g clip-rule=\"evenodd\" fill=\"#919ba1\" fill-rule=\"evenodd\" mask=\"url(#a)\"><path d=\"M4.07 21.738a.743.743 0 01-.734-.75c0-3.88 2.356-7.199 5.653-8.478-1.494-1.024-2.482-2.787-2.482-4.786C6.507 4.568 8.97 2 11.997 2c3.028 0 5.491 2.568 5.491 5.724 0 1.998-.987 3.76-2.479 4.784a8.608 8.608 0 013.197 2.177c1.582 1.698 2.453 3.936 2.453 6.303 0 .414-.33.75-.733.75zm7.716-8.287a7.023 7.023 0 00-4.66 1.99 7.663 7.663 0 00-2.275 4.667l-.013.13h14.318l-.012-.13a7.682 7.682 0 00-2.202-4.598 7.022 7.022 0 00-4.522-2.05l-.21-.009-.18-.003H11.959zM7.974 7.724c0 2.329 1.805 4.224 4.024 4.224 2.219 0 4.023-1.895 4.023-4.224S14.217 3.5 11.998 3.5c-2.219 0-4.024 1.895-4.024 4.224z\"/><path d=\"M20.2 20.395a.9.9 0 01-.113-1.793l.113-.007h2.022l-.034-.18c-.47-2.231-2.36-3.915-4.645-4.015l-.215-.004a.9.9 0 01-.9-.9v-.014a3.389 3.389 0 01-.96-2.365c0-1.875 1.526-3.4 3.4-3.4s3.4 1.525 3.4 3.4a3.394 3.394 0 01-1.223 2.61 6.928 6.928 0 013.055 5.768.9.9 0 01-.787.893l-.113.007zm-2.932-9.278c0 .882.718 1.6 1.6 1.6s1.6-.718 1.6-1.6-.717-1.6-1.6-1.6-1.6.718-1.6 1.6zM.9 20.395l-.113-.007A.899.899 0 010 19.495a6.928 6.928 0 013.055-5.768 3.394 3.394 0 01-1.224-2.61c0-1.875 1.526-3.4 3.4-3.4s3.4 1.525 3.4 3.4c0 .918-.365 1.752-.959 2.365v.014a.9.9 0 01-.9.9l-.215.004c-2.286.1-4.176 1.784-4.645 4.014l-.034.181H3.9l.113.007a.9.9 0 01-.113 1.793zm2.731-9.278c0 .882.718 1.6 1.6 1.6.883 0 1.6-.718 1.6-1.6s-.717-1.6-1.6-1.6c-.882 0-1.6.718-1.6 1.6z\"/></g>",
    "attrs": {
      "fill": "none",
      "height": "24",
      "viewBox": "0 0 24 24",
      "width": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});