define("ember-svg-jar/inlined/chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M2.009 19.167a.755.755 0 01-.634-.35.75.75 0 01-.05-.725l1.042-2.275-.2-.217a8.907 8.907 0 01-1.333-2l-.159-.333A8.591 8.591 0 010 9.942a8.45 8.45 0 012.925-6.45A10.452 10.452 0 019.984.833C15.508.833 20 4.917 20 9.933a8.67 8.67 0 01-2.933 6.442 10.494 10.494 0 01-7.075 2.659c-1.15 0-2.292-.184-3.383-.525l-.109-.026H6.46l-4.317.675s-.083.009-.117.009H2.01zm4.475-2.2c.125 0 .258.017.375.042l.133.033a10.11 10.11 0 002.992.475A8.967 8.967 0 0016 15.292a7.157 7.157 0 002.483-5.359c0-4.183-3.816-7.583-8.5-7.583a8.89 8.89 0 00-6.05 2.275A7.04 7.04 0 002.06 12.66c.35.783.825 1.508 1.416 2.133l.092.091a1.43 1.43 0 01.192 1.534l-.475 1.025 2.9-.442h.033l.1-.025h.158l.009-.008z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "#919BA1",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});