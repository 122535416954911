define("ember-svg-jar/inlined/badge-social", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20.924 17.353c0 1.963-1.62 3.566-3.588 3.566h-1.79l-1.003 1.007c-1.377 1.428-3.662 1.43-5.073.015l-1.019-1.023H6.664a3.57 3.57 0 01-3.59-3.565v-1.799l-1.018-1.021a3.567 3.567 0 01-.003-5.064l1.021-1.024V6.647c0-1.963 1.62-3.566 3.588-3.566h1.79l1.003-1.007c1.377-1.428 3.663-1.43 5.073-.015l1.02 1.023h1.786a3.57 3.57 0 013.59 3.565v1.799l1.021 1.024c1.407 1.41 1.407 3.696 0 5.06l-1.02 1.026-.001 1.797z\" fill=\"url(#paint0_linear_726_36612)\" stroke=\"#F1F2F3\" stroke-width=\"1.8\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.5 12l3.576-3.576 3.25 3.25 2.598 2.598L17.196 12l-2.272-2.272-1.574 1.574-.652-.652 2.226-2.226L18.5 12l-3.576 3.576-3.25-3.25-2.598-2.598L6.804 12l2.272 2.272 1.574-1.574.652.652-2.226 2.226L5.5 12z\" fill=\"#fff\"/><defs><linearGradient id=\"paint0_linear_726_36612\" x1=\"4.063\" y1=\"19\" x2=\"20.188\" y2=\"4.125\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#3FA5A7\"/><stop offset=\"1\" stop-color=\"#55C89F\"/></linearGradient></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});