define("ember-svg-jar/inlined/badge-verified", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20.924 17.353c0 1.963-1.62 3.566-3.588 3.566h-1.79l-1.003 1.007c-1.377 1.428-3.662 1.43-5.073.015l-1.019-1.023H6.664a3.57 3.57 0 01-3.59-3.565v-1.799l-1.018-1.021a3.567 3.567 0 01-.003-5.064l1.021-1.024V6.647c0-1.963 1.62-3.566 3.588-3.566h1.79l1.003-1.007c1.377-1.428 3.663-1.43 5.073-.015l1.02 1.023h1.786a3.57 3.57 0 013.59 3.565v1.799l1.021 1.024c1.407 1.41 1.407 3.696 0 5.06l-1.02 1.026-.001 1.797z\" fill=\"url(#paint0_linear_107_38896)\" stroke=\"#F1F2F3\" stroke-width=\"1.8\"/><path d=\"M8.63 11.5a.9.9 0 10-1.26 1.285L8.63 11.5zm1.648 4.142a.9.9 0 101.261-1.284l-1.26 1.284zm6.353-5a.9.9 0 10-1.261-1.284l1.26 1.284zm-6.353 3.716a.9.9 0 101.261 1.284l-1.26-1.284zM7.37 12.785l2.91 2.857 1.26-1.284-2.908-2.857-1.262 1.284zm4.17 1.573l-2.908-2.857-1.262 1.284 2.91 2.857 1.26-1.284zm3.83-5l-5.09 5 1.26 1.284 5.092-5-1.261-1.284zm-3.83 6.284l5.092-5-1.261-1.284-5.092 5 1.261 1.284z\" fill=\"#F1F2F3\"/><defs><linearGradient id=\"paint0_linear_107_38896\" x1=\"1\" y1=\"23\" x2=\"21.344\" y2=\"2.981\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#1835BD\"/><stop offset=\".298\" stop-color=\"#1C81D5\"/><stop offset=\".663\" stop-color=\"#05A7D3\"/><stop offset=\"1\" stop-color=\"#37CDBD\"/></linearGradient></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});