define("ember-svg-jar/inlined/close-round", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 0h16v16H0V0z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.157 2.845a.608.608 0 01.054.797l-.054.062L8.86 8l4.296 4.296a.608.608 0 01-.797.914l-.063-.054L8.001 8.86l-4.296 4.296a.608.608 0 01-.913-.797l.054-.063L7.142 8 2.846 3.704a.608.608 0 01.797-.913l.062.054 4.296 4.296 4.296-4.296a.608.608 0 01.86 0z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "class": "rounded-3",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});