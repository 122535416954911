define("ember-svg-jar/inlined/flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19 4H6V2H4v18H3v2h4v-2H6v-5h13a1 1 0 001-1V5a1 1 0 00-1-1zm-1 9H6V6h12v7z\" fill=\"#32475C\" fill-opacity=\".6\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});