define("ember-svg-jar/inlined/user-basic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.735 16.5H2.25v-.668a6.89 6.89 0 014.215-6.375A4.423 4.423 0 014.68 5.896 4.355 4.355 0 018.993 1.5a4.36 4.36 0 014.312 4.395c0 1.403-.66 2.723-1.785 3.555.87.36 1.658.9 2.31 1.59a6.902 6.902 0 011.905 4.793v.667zm-6.922-6.21a5.339 5.339 0 00-3.465 1.44 5.584 5.584 0 00-1.718 3.375v.053h10.725v-.053a5.47 5.47 0 00-5.01-4.808h-.532v-.007zM6.015 5.88a3.027 3.027 0 002.978 3.06 3.027 3.027 0 002.977-3.06 3.027 3.027 0 00-2.977-3.06 3.027 3.027 0 00-2.978 3.06z\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "#919BA1",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});