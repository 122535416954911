define("ember-svg-jar/inlined/event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<mask id=\"a\" style=\"mask-type:luminance\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"24\" height=\"24\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 0h24v24H0V0z\" fill=\"#fff\"/></mask><g mask=\"url(#a)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.527 24C2.031 24 0 21.926 0 19.376V6.642c0-2.55 2.031-4.625 4.527-4.625h2.028V.907A.908.908 0 018.362.795l.008.114v1.109h7.26V.907a.908.908 0 011.808-.113l.008.114v1.109h2.027c1.204 0 2.34.483 3.198 1.362A4.643 4.643 0 0124 6.642v12.734C24 21.926 21.969 24 19.473 24H4.527zm-2.712-4.624c0 1.55 1.216 2.809 2.712 2.809h14.946c1.496 0 2.712-1.26 2.712-2.81v-7.98H1.815v7.98zm20.37-9.796V6.642c0-.745-.285-1.45-.802-1.983a2.647 2.647 0 00-1.91-.827h-2.027v1.613a.907.907 0 01-1.808.114l-.008-.114V3.832H8.37v1.613a.907.907 0 01-1.808.114l-.007-.114V3.832H4.527c-1.496 0-2.712 1.26-2.712 2.81V9.58h20.37z\" fill=\"#919BA1\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});