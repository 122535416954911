define("ember-svg-jar/inlined/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3.773 19.166A3.814 3.814 0 010 15.491V4.69A3.822 3.822 0 013.595.838l.178-.004h8.091a.757.757 0 01.095 1.508l-.095.005H3.773a2.3 2.3 0 00-2.256 2.201V15.31a2.312 2.312 0 002.123 2.339h12.587a2.298 2.298 0 002.256-2.2V7.473a.757.757 0 011.508-.095l.01.1v7.832a3.823 3.823 0 01-3.595 3.856H3.773zm2.184-4.22a1.124 1.124 0 01-.543-.272l-.091-.083a1.107 1.107 0 01-.264-.925l.348-1.916c.037-.227.15-.434.32-.589h-.003L15.75 1.151l.063-.056c.198-.166.447-.259.705-.262.26 0 .511.092.708.262l.066.059 1.555 1.57a1.087 1.087 0 010 1.529L8.824 14.267l-.017.021a.987.987 0 01-.455.273l-.108.028-1.85.333-.037.013a.729.729 0 01-.161.024h-.083l-.156-.013zm.917-2.785l-.22 1.172 1.167-.21 9.65-9.637-.954-.965-9.643 9.64z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "#919BA1",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});