define("ember-svg-jar/inlined/picture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.883 19.166H4.117A4.173 4.173 0 011.2 17.983 4.003 4.003 0 010 15.125V4.875c0-1.067.433-2.092 1.192-2.842a4.187 4.187 0 012.925-1.2h11.766A4.094 4.094 0 0120 4.875v10.25a4.005 4.005 0 01-1.192 2.841 4.187 4.187 0 01-2.925 1.2zM4.117 2.416c-.65 0-1.275.259-1.742.717a2.432 2.432 0 00-.725 1.742v10.25c0 .658.267 1.283.733 1.741a2.446 2.446 0 001.734.709h11.766c.65 0 1.275-.259 1.742-.717a2.398 2.398 0 00.725-1.742V4.875a2.44 2.44 0 00-.733-1.742 2.454 2.454 0 00-1.734-.717H4.117zM16.25 14.758a.825.825 0 01-.592-.242L12.667 11.5l-2.992 3.016a.848.848 0 01-.583.234H9a.844.844 0 01-.6-.242l-1.975-2.025L4.45 14.5a.845.845 0 01-1.167.033.776.776 0 01-.066-1.092c.008-.008.016-.016.025-.033l2.583-2.642.017-.016s.008-.017.016-.017a.787.787 0 01.2-.117s.017 0 .025-.016c0 0 .017-.009.025-.017a.712.712 0 01.192-.042h.05s.05-.016.075-.016c.025 0 .05 0 .075.008h.05l.192.05c.008 0 .016.008.025.017 0 0 .016.008.016.016.067.034.134.067.2.117 0 0 .009.017.017.017 0 0 0 .016.017.016l2.025 2.075 2.933-2.966a.848.848 0 01.583-.234h.2c.217 0 .425.075.584.234l3.5 3.525c.3.3.308.791 0 1.091-.009.009-.017.017-.034.025a.84.84 0 01-.583.225l.025.017zM6.075 8.55a1.462 1.462 0 01-1.492-1.434 1.491 1.491 0 011.55-1.433c.775.033 1.4.65 1.434 1.433A1.467 1.467 0 016.075 8.55z\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "#919BA1",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});