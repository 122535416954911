define("ember-svg-jar/inlined/key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M22.846 24h-3.279a1.134 1.134 0 01-1.147-1.026l-.007-.128v-1.707h-1.706a1.14 1.14 0 01-1.128-.902l-.02-.124-.006-.128V18.28H13.7c-.225 0-.431-.06-.614-.178l-.104-.074-.096-.088-2.222-2.221-.216.072a8.049 8.049 0 01-8.034-1.945l-.212-.213-.199-.217C-.595 10.493-.675 5.998 1.818 2.959L2 2.744l.19-.206A8.005 8.005 0 018.065 0c2.161 0 4.197.846 5.734 2.384a8.073 8.073 0 012.083 7.779l-.08.282-.083.244 7.94 7.94c.158.158.263.343.311.55l.022.13.007.134v3.403c0 .603-.431 1.085-1.026 1.147l-.128.007zM10.89 13.753c.198 0 .393.065.55.184l.093.081 2.445 2.446h2.236c.552 0 1.016.372 1.128.903l.02.123.007.128-.001 1.706h1.706c.552 0 1.016.372 1.128.903l.02.124.007.128-.001 1.706h1.957V19.72l-8.165-8.164a.913.913 0 01-.256-.769l.024-.119.042-.115c.496-1.143.635-2.4.403-3.632a6.212 6.212 0 00-1.717-3.254 6.256 6.256 0 00-4.45-1.852c-1.728 0-3.338.69-4.534 1.944l-.175.19-.167.2a6.294 6.294 0 00-1.375 3.806 6.253 6.253 0 001.206 3.838l.167.214.172.201a6.3 6.3 0 004.705 2.13c.852 0 1.681-.172 2.465-.51a.902.902 0 01.36-.075zM6.662 9.36a2.71 2.71 0 01-2.248-1.198 2.74 2.74 0 01.002-3.009 2.704 2.704 0 014.257-.298l.12.143.11.154c.6.901.6 2.112.002 3.013A2.703 2.703 0 016.662 9.36zm-.003-3.589v.001a.885.885 0 00-.735.391.924.924 0 00-.004.987.894.894 0 00.742.396c.295 0 .57-.146.734-.39a.944.944 0 00.063-.88l-.063-.113-.073-.093a.89.89 0 00-.664-.298z\" fill=\"#919BA1\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});