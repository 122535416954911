define("ember-svg-jar/inlined/groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<mask id=\"a\" style=\"mask-type:luminance\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"24\" height=\"24\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 0h24v24H0V0z\" fill=\"#fff\"/></mask><g mask=\"url(#a)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.395 24a5.612 5.612 0 01-5.605-5.605 5.612 5.612 0 015.605-5.605A5.612 5.612 0 0124 18.395 5.612 5.612 0 0118.395 24zm0-9.395c-2.09 0-3.79 1.7-3.79 3.79s1.7 3.79 3.79 3.79 3.79-1.7 3.79-3.79-1.7-3.79-3.79-3.79zM5.605 24A5.612 5.612 0 010 18.395a5.612 5.612 0 015.605-5.605 5.612 5.612 0 015.606 5.605A5.612 5.612 0 015.605 24zm0-9.395c-2.09 0-3.79 1.7-3.79 3.79s1.7 3.79 3.79 3.79 3.791-1.7 3.791-3.79-1.7-3.79-3.79-3.79zm12.79-3.394a5.612 5.612 0 01-5.605-5.606A5.612 5.612 0 0118.395 0 5.612 5.612 0 0124 5.605a5.612 5.612 0 01-5.605 5.606zm0-9.396c-2.09 0-3.79 1.7-3.79 3.79s1.7 3.791 3.79 3.791 3.79-1.7 3.79-3.79-1.7-3.79-3.79-3.79zm-12.79 9.396A5.612 5.612 0 010 5.605 5.612 5.612 0 015.605 0a5.612 5.612 0 015.606 5.605 5.612 5.612 0 01-5.606 5.606zm0-9.396c-2.09 0-3.79 1.7-3.79 3.79s1.7 3.791 3.79 3.791 3.791-1.7 3.791-3.79-1.7-3.79-3.79-3.79z\" fill=\"#919BA1\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});