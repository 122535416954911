define("ember-svg-jar/inlined/blocked-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<mask id=\"a\" style=\"mask-type:luminance\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"24\" height=\"24\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 0h24v24H0V0z\" fill=\"#fff\"/></mask><g mask=\"url(#a)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 21.107V22h10.08v-1.787H3.84l.007-.073c.438-3.45 3.19-6.148 6.599-6.42a5.707 5.707 0 001.318-.021h.14v-.02c2.754-.44 4.87-2.875 4.87-5.805C16.774 4.635 14.188 2 11.011 2 7.832 2 5.247 4.635 5.247 7.874c0 1.934.922 3.653 2.341 4.724-3.204 1.344-5.48 4.518-5.584 8.242L2 21.107zm8.49-9.18c-1.948-.263-3.456-1.98-3.456-4.053 0-2.253 1.784-4.087 3.977-4.087 2.192 0 3.977 1.833 3.977 4.087 0 2.037-1.457 3.73-3.355 4.038h-.623c-.175 0-.348.005-.52.015zm3.487 2.1a4.7 4.7 0 106.645 6.647 4.7 4.7 0 00-6.645-6.647zm1.273 5.374a2.881 2.881 0 01-.85-2.052c0-.477.114-.936.33-1.346l3.916 3.916c-.41.215-.87.33-1.347.33a2.886 2.886 0 01-2.049-.848zm4.949-2.052c0 .452-.102.887-.295 1.281l-3.886-3.883a2.894 2.894 0 011.281-.298 2.89 2.89 0 012.9 2.9z\" fill=\"#919BA1\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});