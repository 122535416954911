define("ember-svg-jar/inlined/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<mask id=\"a\" style=\"mask-type:luminance\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"24\" height=\"24\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 0h24v24H0V0z\" fill=\"#fff\"/></mask><g mask=\"url(#a)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.527 23c-2.41 0-4.395-1.936-4.522-4.406L0 18.372V5.63c0-2.463 1.893-4.494 4.31-4.624L4.527 1h14.946c2.408 0 4.395 1.936 4.523 4.409l.004.22v12.743c0 2.462-1.893 4.493-4.31 4.623l-.217.005H4.527zm-2.712-4.651l.004.175c.076 1.491 1.266 2.66 2.708 2.66h14.921l.17-.004c1.439-.078 2.567-1.31 2.567-2.808v-7.987H1.816v7.964zM4.383 2.82c-1.44.078-2.568 1.312-2.568 2.809v2.94h20.37V5.65l-.004-.172a2.844 2.844 0 00-.848-1.887c-.51-.5-1.17-.775-1.86-.775H4.552l-.17.003zm1.062 15.336a.908.908 0 01-.113-1.81l.113-.006h13.11a.908.908 0 01.114 1.809l-.114.007H5.445zm0-3.027a.908.908 0 01-.113-1.81l.113-.007h13.11a.907.907 0 01.114 1.81l-.114.007H5.445z\" fill=\"#007DA1\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});