define("ember-svg-jar/inlined/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.532 26.466c-6.043-6.058-6.043-15.874 0-21.932 6.043-6.058 15.87-6.045 21.926 0 6.056 6.058 6.056 15.861 0 21.92-6.056 6.057-15.857 6.057-21.913 0l-.013.012zM2.337 15.5c0 7.272 5.888 13.162 13.145 13.162S28.64 22.772 28.64 15.5c0-7.272-5.888-13.162-13.145-13.162S2.35 8.228 2.35 15.5h-.013zm11.996 7.31v-7.956c0-.646.53-1.175 1.175-1.162.581 0 1.072.439 1.15 1.02v7.957a1.16 1.16 0 01-1.176 1.162c-.58 0-1.071-.439-1.149-1.02zm1.162-10.965a1.948 1.948 0 01-1.95-1.95c0-1.073.878-1.951 1.95-1.951 1.085 0 1.95.878 1.95 1.95s-.878 1.95-1.95 1.95z\" fill=\"#000\"/>",
    "attrs": {
      "width": "31",
      "height": "31",
      "viewBox": "0 0 31 31",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});