define("ember-svg-jar/inlined/storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<mask id=\"a\" style=\"mask-type:luminance\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"24\" height=\"24\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 0h24v24H0V0z\" fill=\"#fff\"/></mask><g mask=\"url(#a)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.873 2.5c.717 0 1.386.308 1.845.844l.111.139.097.142 3.243 5.136a.87.87 0 01-.284 1.212.9.9 0 01-1.079-.104l-.084-.088-.064-.089-3.244-5.137a.632.632 0 00-.36-.272l-.089-.018-.092-.006H7.127a.637.637 0 00-.425.158l-.062.064-.054.074-4.09 6.478 3.556.001c.96 0 1.836.473 2.347 1.243l.098.157.087.166.075.171.062.172C9.178 14.354 10.494 15.3 12 15.3a3.43 3.43 0 003.142-2.003l.073-.176.063-.178a2.773 2.773 0 012.292-1.884l.19-.019.187-.006h5.161a.89.89 0 01.863.66l.02.108.009.11v6.775c0 1.431-1.092 2.624-2.512 2.793l-.173.015-.169.005H2.854c-1.451 0-2.662-1.075-2.833-2.476l-.016-.17L0 18.687v-6.775c0-.082.012-.164.036-.245l.046-.12.053-.099 4.939-7.822c.38-.602.998-.999 1.702-1.1l.178-.019.173-.006 9.746-.001zM6.043 12.792H1.782l.001 5.896c0 .502.36.929.841 1.031l.113.017.118.006h18.292a1.07 1.07 0 001.048-.828l.017-.11.007-.116-.003-5.896h-4.27c-.364 0-.691.182-.87.467l-.06.112-.041.107c-.693 2.14-2.694 3.58-4.975 3.58-2.282 0-4.283-1.439-4.976-3.58a.993.993 0 00-.735-.661l-.117-.019-.127-.006zM17.188 8.2c.492 0 .891.393.891.879a.881.881 0 01-.671.851l-.109.021-.111.007H6.812a.883.883 0 01-.89-.879c0-.405.278-.75.67-.851l.11-.021.11-.007h10.375zm-2.894-2.7c.492 0 .89.393.89.879a.88.88 0 01-.67.851l-.11.021-.11.007H9.707a.884.884 0 01-.891-.879c0-.405.279-.75.671-.851l.109-.021.111-.007h4.586z\" fill=\"#919BA1\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});