define("ember-svg-jar/inlined/comments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.563 19.917l-.084-.05-4.854-3.234H9.183a.756.756 0 01-.75-.666v-.092c0-.383.275-.708.659-.758h1.767c.108 0 .217.016.325.066l.092.059 3.87 2.575v-1.942c0-.383.283-.708.659-.758h.934a1.775 1.775 0 001.76-1.659V7.033a.755.755 0 01.734-.775.749.749 0 01.767.675v6.392a3.289 3.289 0 01-3.103 3.308h-.25v2.6a.763.763 0 01-.759.767.76.76 0 01-.333-.083h.008zM3.369 16.808v-3.533h-.091A3.29 3.29 0 010 10.133V3.308A3.3 3.3 0 013.278 0h10.925a3.295 3.295 0 013.277 3.308v6.659a3.3 3.3 0 01-3.277 3.308H10.25l-5.688 4.058a.73.73 0 01-.434.142.757.757 0 01-.75-.667h-.009zm-1.851-13.5v6.659c0 .975.784 1.775 1.768 1.783h.842c.417 0 .76.342.76.767v2.725l4.695-3.35a.72.72 0 01.342-.134h4.294a1.79 1.79 0 001.769-1.791V3.308c0-.975-.784-1.775-1.769-1.783H3.278c-.976 0-1.768.8-1.768 1.783h.008z\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "#919BA1",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});