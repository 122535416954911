define("ember-svg-jar/inlined/dsnp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<clipPath id=\"a\"><path d=\"M0 5.5h24v13H0z\"/></clipPath><g clip-path=\"url(#a)\" fill=\"#919ba1\"><path d=\"M6.602 5.5L0 12l6.602 6.5 4.108-4.046-1.203-1.184-2.905 2.86L2.407 12l4.195-4.13 4.797 4.722 1.202-1.185z\"/><path d=\"M17.398 5.5l-4.109 4.045 1.204 1.185 2.905-2.86L21.593 12l-4.195 4.13-4.797-4.723-1.203 1.185 6 5.908L24 12z\"/></g>",
    "attrs": {
      "fill": "none",
      "height": "24",
      "viewBox": "0 0 24 24",
      "width": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});