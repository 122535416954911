define("ember-svg-jar/inlined/badge-love-mewe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20.924 17.353c0 1.963-1.62 3.566-3.588 3.566h-1.79l-1.003 1.007c-1.377 1.428-3.662 1.43-5.073.015l-1.019-1.023H6.664a3.57 3.57 0 01-3.59-3.565v-1.799l-1.018-1.021a3.567 3.567 0 01-.003-5.064l1.021-1.024V6.647c0-1.963 1.62-3.566 3.588-3.566h1.79l1.003-1.007c1.377-1.428 3.663-1.43 5.073-.015l1.02 1.023h1.786a3.57 3.57 0 013.59 3.565v1.799l1.021 1.024c1.407 1.41 1.407 3.696 0 5.06l-1.02 1.026-.001 1.797z\" fill=\"url(#paint0_linear_107_38906)\" stroke=\"#F1F2F3\" stroke-width=\"1.8\"/><path d=\"M16.277 9.184c.966.909.966 2.372 0 3.283l-4.273 4.035a4383.44 4383.44 0 00-4.279-4.03c-.967-.91-.967-2.376 0-3.285.966-.909 2.52-.909 3.487 0l.787.742.792-.747c.965-.91 2.52-.91 3.486 0v.002z\" fill=\"#fff\"/><defs><linearGradient id=\"paint0_linear_107_38906\" x1=\"17\" y1=\"1\" x2=\"5\" y2=\"21.5\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#FF8383\"/><stop offset=\"1\" stop-color=\"#CD3636\"/></linearGradient></defs>",
    "attrs": {
      "width": "24",
      "height": "25",
      "viewBox": "0 0 24 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});