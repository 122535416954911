define("ember-svg-jar/inlined/flags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<mask id=\"a\" style=\"mask-type:luminance\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"16\" height=\"16\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 0h16v16H0V0z\" fill=\"#fff\"/></mask><g mask=\"url(#a)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.931 16a.602.602 0 01-.598-.605V.604A.598.598 0 011.931 0h7.374a.598.598 0 01.598.604V2.24h4.165a.603.603 0 01.466.982L12.06 6.328l-.002.012.002.013 2.473 3.106a.606.606 0 01-.466.983H6.131a.598.598 0 01-.598-.604V7.793H2.53v7.602A.602.602 0 011.93 16zm.598-14.792v5.377H6.13c.158 0 .312.064.422.176a.613.613 0 01.175.428v2.045h6.092l-1.695-2.128c-.17-.216-.26-.481-.26-.766 0-.285.09-.55.26-.764l1.695-2.13h-2.814v3.125c0 .227-.023.393-.074.556a.965.965 0 01-.334.483.913.913 0 01-.324.151 1.308 1.308 0 01-.319.033 8.19 8.19 0 01-.162 0h-.152a.6.6 0 01-.597-.605.596.596 0 01.598-.604l.066-.067v-5.31h-6.18z\" fill=\"#000\"/></g>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});