define("ember-svg-jar/inlined/copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 0h24v24H0V0z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.527 24c-2.41 0-4.395-1.934-4.522-4.403L0 19.375v-7.691c0-2.46 1.893-4.49 4.31-4.62l.217-.005h.516V4.625c0-2.479 1.914-4.506 4.313-4.62L9.57 0h9.904c2.437 0 4.412 1.966 4.522 4.408l.005.217v7.691c0 2.462-1.92 4.505-4.315 4.62l-.212.005h-.515v2.434c0 2.46-1.893 4.489-4.31 4.62l-.217.005H4.527zM4.383 8.878c-1.44.077-2.568 1.31-2.568 2.806v7.668l.004.176c.076 1.49 1.266 2.657 2.708 2.657h9.88l.168-.005c1.44-.078 2.568-1.31 2.568-2.805v-7.669l-.003-.172a2.841 2.841 0 00-.849-1.885 2.65 2.65 0 00-1.86-.775h-9.88l-.168.004zm2.48-4.426l-.005.173v2.434h7.573c2.408 0 4.394 1.935 4.522 4.405l.005.22v3.442h.515c1.424 0 2.62-1.175 2.707-2.64l.005-.17V4.625c0-1.495-1.13-2.716-2.547-2.804l-.165-.006H9.57c-1.433 0-2.622 1.16-2.708 2.637zm-1.418 15.01a.907.907 0 01-.113-1.808l.113-.008h8.068a.908.908 0 01.113 1.809l-.113.006H5.445zm0-3.026a.908.908 0 01-.113-1.807l.113-.008h8.068a.908.908 0 01.113 1.808l-.113.008H5.445zm0-3.024a.908.908 0 01-.113-1.808l.113-.007h8.068a.907.907 0 01.113 1.807l-.113.008H5.445z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "#919BA1",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});